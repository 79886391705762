/* Form */
.form {
    .form__field {
      margin: 0 0 1rem 0;
      position: relative;
      position: relative;
      label {
        margin: 0 0 0.5rem 0;
      }
      .form__input {
        border: 1px solid $border-color;
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 2px;
        color: $dark;
        font-size: 1rem;
        font-weight: 600;
        &:focus {
          outline: 1px solid $secondary;
          border: 1px solid $secondary;
        }
        &::-webkit-input-placeholder {
          color: $dark;
          font-weight: 400;
          opacity: 0.75;
        }
        &::-moz-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &:-ms-input-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &.form__input--no-focus {
          &:focus {
            border: 0 !important;
            outline: 0 !important;
          }
        }
      }
      select {
        border: 1px solid $border-color;
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 5px;
        color: $dark;
        font-size: 0.9375rem;
        
        font-weight: 600;
        &:focus {
          outline: 1px solid $secondary;
          border: 1px solid $secondary;
        }
        &::-webkit-input-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &::-moz-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &:-ms-input-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
      }
      textarea {
        border: 1px solid var(--colorBorder);
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 5px;
        color: $dark;
        font-size: 0.9375rem;
        font-family: var(--fontPrimary);
        font-weight: 600;
        height: 120px;
        &:focus {
          outline: 1px solid $secondary;
          border: 1px solid $secondary;
        }
        &::-webkit-input-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &::-moz-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
        &:-ms-input-placeholder {
          color: $dark;
          font-weight: 400;
          color: $dark;
          opacity: 0.75;
        }
      }
      .anim-label {
        position: absolute;
        left: 0;
        font-size: 0.9375rem;
        top: calc(50% - 10px);
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
        display: inline-block;
      }
      .anim-label.anim-label--shrinked {
        top: -4px;
        font-size: 0.75rem;
      }
      .button--show-password {
        position: absolute;
        right: 8px;
        top: 40px;
        font-size: 1.25rem;
        color: $dark;
      }
      .error {
        margin: 0.5rem 0 0 0;
        font-weight: 500;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        transition-delay: 9999s;
      }
    }
    .form__field.form__field--textarea {
      .anim-label {
        top: 0;
      }
    }
  }
  input[type='checkbox'] {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      &:hover {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    &:checked {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }
  }
  .nice-select {
    border: 1px solid var(--colorBorder);
    border-radius: var(--borderRadius);
    .list {
      border: none;
      -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
      border-radius: var(--borderRadius);
    }
    .option {
      &:hover {
        background: var(--colorLightBg);
      }
    }
    .option.focus {
      background: var(--colorLightBg);
    }
    .option.selected.focus {
      background: var(--colorLightBg);
    }
    &:active {
      border: 1px solid $secondary;
    }
    &:focus {
      border: 1px solid $secondary;
    }
  }
  .nice-select.open {
    border: 1px solid $secondary;
  }
  .select2-container--bootstrap-5 {
    .select2-selection {
      font-size: 0.9375rem;
      min-height: 48.5px;
      border: 1px solid $border-color;
    }
    .select2-selection--single {
      .select2-selection__rendered {
        padding: 0.375rem 0.25rem;
        font-weight: 600;
      }
    }
    .select2-dropdown {
      border-color: $border-color;
      .select2-search {
        .select2-search__field {
          &:focus {
            outline: none;
            border: 2px solid $secondary;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }
      .select2-results__options {
        .select2-results__option {
          padding: 0.5rem 0.75rem;
          font-size: 0.9375rem;
          color: $dark;
          &:hover {
            background-color: $light;
          }
        }
        .select2-results__option.select2-results__option--selected {
          background-color: $secondary;
          &:hover {
            color: $dark;
          }
        }
        .select2-results__option[aria-selected='true'] {
          &:not(.select2-results__option--highlighted) {
            background-color: $secondary;
            &:hover {
              color: $dark;
            }
          }
        }
      }
    }
  }
  .select2-container--bootstrap-5.select2-container--focus {
    .select2-selection {
      border-color: $secondary;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .select2-container--bootstrap-5.select2-container--open {
    .select2-selection {
      border-color: $secondary;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  select {
    + {
      .select2-container--bootstrap-5 {
        width: 100% !important;
      }
    }
  }
  label.error {
    color: $danger;
    margin: 0.25rem 0 0 0;
    font-size: 0.9375rem;
  }
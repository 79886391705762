.hero-section {
    position: relative;
    padding: 3.125rem 0;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    background-color: $dark;

    .container,
    .row {
        height: 100%; 

        
    }

    .hero-section__img-container {
        position: absolute;
        top:0;
        right: 0;
        width: 54%;
        height: 100%;
        background-color: $dark;
        clip-path: polygon(14% 0, 100% 0%, 100% 100%, 0% 100%);
    }
    &.hero-section--hidden {
        display: none;
    }
}
@include media-breakpoint-up(lg) {
    .hero-section {
        height: 100vh;
        padding: 0;

        .content-col {
            padding-right: 3.75rem;
        }
    }
}

// Survery Form Section
.survey-form-section {
    width: 100%;
    background-color: $dark;
    background-image: url(../images/banner-2.jfif);
    @include backgroundImageSetting(center, cover, no-repeat);
    width: 100vw;
    height: 100%;
    z-index: 9;
    transition: transform 0.3s ease-in-out;
    display: none;


    .container {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;

        .form {
            width: 100%;
            .form__field {
                background-color: $light;
                width: 100%;
                margin: 0;
                padding: 1.5rem;
                border-radius: 10px;
                display: none;

                .form__label--question {
                    font-size: 1rem;
                }
                input[type="radio"] {
                    accent-color: $primary;
                    width: 22px;
                    height: 22px;
                }
                .form__label--ans {
                    margin: 0 0 0 0.75rem;
                    font-size: 0.9375rem;
                }
                .form__field--error {
                    display: none;
                }
                .form__btn-container {
                    margin-top: 1.5rem;
                }
               &.form__field--active {
                display: block;
               } 
            }
        }

        .progress-measure .progress {
            height: 5px;
        }
    }
    &.survey-form-section--active {
       display: block;
    }
    .block-logo {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .progress-measure {
        position: absolute;
        left: 50%;
        top: calc(100% - 20px);
        transform: translate(-50%, -50%);
        z-index: 900;
        width: 250px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($light, 0.85);
    }

}
@include media-breakpoint-up(md) {
    .survey-form-section {
        .container {
            .row {
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
 .survey-form-section {
    .container {
        display: block;

        .row {
            height: 100%;
        }

        .form {
            .form__field {
                padding: 0;

                .form__label--question {
                    font-size: 1.15rem;
                }
                .form__label--ans {
                    font-size: 0.9375rem;
                }
                .form__btn-container {
                    margin-top: 1.875rem;
                }
            }
        }
    .progress-value {
        margin-bottom: 0.5rem;
    }
    }
    &::before {
        width: 64%;
        height: 100%;
        background-color: rgba($light, 1);
        clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
    }
    .progress-measure {
        top: auto;
        bottom: 20px;
        right: 20px;
        transform: none;
        left: auto;
    }
 }
}
@include media-breakpoint-up(xl) {
    .survey-form-section {
        height: 100vh;
        .container {
            .form {
                .form__field {
                    .form__label--question {
                        font-size: 1.5rem;
                    }
                    .form__label--ans {
                        font-size: 1.0625rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .survey-form-section {
        .container {
            .form {
                .form__field {
                    .form__label--question {
                        font-size: 1.875rem;
                    }
                    .form__label--ans {
                        font-size: 1.15rem;
                    }
                }
            }
        }
    }
}

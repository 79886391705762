// Thank you section
.thank-you-section {
    width: 100vw;
    height: 100vh;
    background-image: url(../images/banner-2.jfif);
    @include backgroundImageSetting(center, cover, no-repeat);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($dark, 0.8);
    }
}